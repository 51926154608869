import React from 'react';
import HomeDark from '../views/all-home-version/HomeDark';
import NotFound from '../views/NotFound';
import {Route, Routes} from 'react-router-dom';
import ContextProvider from '../Context/ContextProvider';
import ScrollTopBehaviour from '../components/ScrollTopBehaviour';

const AllRoutes = () => {
  return (
      <>
        <ContextProvider>
          <ScrollTopBehaviour/>
          <Routes>
            <Route path="/" element={<HomeDark/>}/>
            <Route path="/about" element={<HomeDark/>}/>
            <Route path="/portfolio" element={<HomeDark/>}/>
            <Route path="/contact" element={<HomeDark/>}/>
            <Route path="/blog" element={<HomeDark/>}/>
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </ContextProvider>
      </>
  );
};
export default AllRoutes;