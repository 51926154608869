import React from 'react';

const Address = () => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map position-absolute"></i>
        <span className="d-block">Address Point</span>Phoenix, AZ.
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <a href="mailto:remy@ninganzaremy.com">
          <i className="fa fa-envelope-open position-absolute"></i>{' '}
        </a>
        <span className="d-block">mail me</span>{' '}
        <a href="mailto:remy@ninganzaremy.com">Remy</a>
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <a href="Tel: (602) 805-6944">
          <i className="fa fa-phone-square position-absolute"></i>{' '}
        </a>
        <span className="d-block">call me</span>{' '}
        <a href="Tel: (602) 805-6944">(602) 805-6944</a>
      </p>
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;
